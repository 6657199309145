import { computed, reactive, readonly } from 'vue';

const layoutConfig = reactive({
    darkTheme: false,
});



export function useLayout() {

    const toggleDarkMode = () => {
        const currentTheme = document.documentElement.getAttribute('data-theme');
        if (currentTheme === 'dark') {
            document.documentElement.setAttribute('data-theme', 'light');
            layoutConfig.darkTheme = false;
          } else {
            document.documentElement.setAttribute('data-theme', 'dark');
            layoutConfig.darkTheme = true;

          }
          
        if (!document.startViewTransition) {
            executeDarkModeToggle();
            return;
        }

        document.startViewTransition(() => executeDarkModeToggle(event));
    };

    const executeDarkModeToggle = () => {
        document.documentElement.classList.toggle('app-dark');
    };

    const isDarkTheme = computed(() => layoutConfig.darkTheme);


    return { layoutConfig: readonly(layoutConfig), toggleDarkMode,isDarkTheme };
}