<template>
    <div class="cluster-feedback-list-table-container">
        <div class="top-container">
            <Breadcrumb>
                <template #title>
                    <h1>
                        Cluster:
                        <span class="underline">

                            {{ clusterLabel }}
                        </span>
                    </h1>
                </template>
            </Breadcrumb>

        </div>
        <div class="card">
            <FeedbackTable :rowsPerPage="rowsPerPage" :feedbackItems="feedbackItems" :isLoading="isLoading" :error="error" :totalRecords="totalRecords" @firstChanged="onFirstChanged" @searchClicked="onSearchClick" @updateFilters="onUpdateFilters"></FeedbackTable>
        </div>

    </div>
</template>
<script setup>
    import { ref, onMounted } from 'vue';
    import feedbackService from '../../services/feedbackService';
    import FeedbackTable from '../Common/FeedbackTable.vue';
    import Breadcrumb from '../Common/Breadcrumb.vue';
    import { useRoute, useRouter } from 'vue-router'
    import { useFeedbackStore } from '../../stores/feedback';
    import { useProjectSlug } from '../../composables/useProjectSlug';
    const { projectSlug } = useProjectSlug();
    const route = useRoute()
    const router = useRouter()

    const feedbackItems = ref(new Array(10).fill(null).map(() => ({}))); // we prefill it like this for the skeletons when empty
    const isLoading = ref(false);
    const error = ref(null);
    const totalRecords = ref(0);
    const rowsPerPage = ref(20);
    const first = ref(0)
    const clusterLabel = ref('')
    const selectedFeedbackType = ref([]);
    const selectedSentiment = ref([]);
    const selectedPriority = ref([]);
    const selectedLanguage = ref([]);
    const searchValue = ref('')

    function onUpdateFilters(filters) {
        selectedFeedbackType.value = filters.feedbackType
        selectedSentiment.value = filters.sentiment
        selectedPriority.value = filters.priority
        selectedLanguage.value = filters.language
        fetchFeedback();
    }
    function onSearchClick(newValue) {
        searchValue.value = newValue
        fetchFeedback();
    }

    function onFirstChanged(first) {
        first.value = first
        fetchFeedback();
    }

    onMounted(() => {
        const feedbackStore = useFeedbackStore()
        first.value = 0
        clusterLabel.value = feedbackStore.lastSelectedClusterLabel
        fetchFeedback();
    });

    async function fetchFeedback() {
        isLoading.value = true;
        error.value = null;
        // Use URLSearchParams to allow multiple values for the same key
        let params = new URLSearchParams();
        selectedFeedbackType.value.forEach(item => params.append('feedback_analysis__feedback_type', item));
        selectedSentiment.value.forEach(item => params.append('feedback_analysis__sentiment', item));
        selectedPriority.value.forEach(item => params.append('priority', item));
        selectedLanguage.value.forEach(item => params.append('language', item));
        // For text search (single value)
        if (searchValue.value !== '') {
            params.append('english_text__icontains', searchValue.value);
        }
        params.append('cluster', route.params.id);
        params.append('offset', first.value);
        try {
            let { paginator, items } = await feedbackService.getFeedbackList(params, projectSlug.value);
            totalRecords.value = paginator.count;
            feedbackItems.value = items;
        } catch (err) {
            feedbackItems.value = [];
            console.error("Error fetching feedback:", err);
            error.value = "Failed to fetch feedback. Please try again later.";
        } finally {
            isLoading.value = false;
        }
    }

</script>
