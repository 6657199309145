
function mapFeedbackFromBackend(feedback) {
  console.log("feedback",)
    return {
      id: feedback.id,
      isLiked: feedback.is_liked,
      feedbackType: feedback.feedback_type,
      feedbackAnalysis: mapFeedbackAnalysisFromBackend(feedback.feedback_analysis),
      priority:feedback.priority ?? null,
      originalId: feedback.original_id,
      source: feedback.source,
      isReview:feedback.is_review,
      isPublic:feedback.is_public,
      title: feedback.title,
      text: feedback.text,
      englishText: feedback.english_text ?? null,
      username: feedback.username,
      name:feedback.name ?? null,
      surname:feedback.surname ?? null,
      email:feedback.email ?? null,
      submittedAt:feedback.submitted_at ?? null,
      rating: feedback.rating,
      replyDate: feedback.reply_date,
      replyText: feedback.reply_text,
      origin: feedback.origin,
      appVersion: feedback.app_version,
      country: feedback.country,
      language: feedback.language,
      createdAt:feedback.created_at,
      cluster:feedback.cluster,
    };
  }

  function mapFeedbackToBackend(feedback) {
    return {
      id: feedback.id,
      isLiked: feedback.isLiked,
      feedbackType: feedback.feedbackType,
      feedbackAnalysis: feedback.feedbackAnalysis,
      original_id: feedback.originalId,
      source: feedback.source,
      is_review: feedback.isReview,
      is_public: feedback.isPublic,
      title: feedback.title,
      text: feedback.text,
      english_text: feedback.englishText,
      username: feedback.username,
      rating: feedback.rating,
      reply_date: feedback.replyDate,
      reply_text: feedback.replyText,
      app_version: feedback.appVersion,
      country: feedback.country,
      language: feedback.language,
      created_at: feedback.createdAt,
      company: feedback.company,
      cluster: feedback.cluster,
    };
}

function mapFeedbackListFromBackend(feedbackList) {
    return feedbackList.map(feedback => mapFeedbackFromBackend(feedback));
}

function mapFeedbackAnalysisFromBackend(feedbackAnalysis) {
    if (!feedbackAnalysis){
      return null
    }else{

    return { 
        id: feedbackAnalysis.id,
        tone: feedbackAnalysis.tone.tones,
        keyPhrases: feedbackAnalysis.key_phrases.map(phrase => ({
          text: phrase.text,
          sentiment: phrase.sentiment
        })),
        tags: feedbackAnalysis.tags.map(tag => tag.name),
        experimentalAnalysis: {
          customerServiceIssuesScore: feedbackAnalysis.experimental_analysis.customer_service_issues_score,
          missingFeaturesScore: feedbackAnalysis.experimental_analysis.missing_features_score,
          productDissatisfactionScore: feedbackAnalysis.experimental_analysis.product_dissatisfaction_score,
          pricingIssuesScore: feedbackAnalysis.experimental_analysis.pricing_issues_score,
          complexityScore: feedbackAnalysis.experimental_analysis.complexity_score
        },
        clusterLabel: feedbackAnalysis.cluster_label ?? null,
        churnRiskScore: feedbackAnalysis.churn_risk_score,
        sentiment: feedbackAnalysis.sentiment,
        feedbackType: feedbackAnalysis.feedback_type ?? null,
        priority: feedbackAnalysis.priority ?? null,
        derivedCsatScore: feedbackAnalysis.derived_csat_score,
        effortEstimateScore: feedbackAnalysis.effort_estimate_score,
        createdAt: feedbackAnalysis.created_at,
        updatedAt: feedbackAnalysis.updated_at,
        feedbackCategory: feedbackAnalysis.feedback_category
      }
    }
     
  }
export {
    mapFeedbackFromBackend,
    mapFeedbackToBackend,
    mapFeedbackListFromBackend,
};