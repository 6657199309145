<template>
  <Layout>
    <template #content>
      <router-view v-slot="{ Component }">
        <keep-alive :include="['Clusters']">
          <component :is="Component" />
        </keep-alive>
      </router-view>
    </template>
  </Layout>
</template>

<script setup>
  import Layout from './pages/Layout.vue'
  import { onMounted, ref } from 'vue';
  import { useAuthStore } from '@/stores/auth';

  onMounted(() => {
    try {
      const authStore = useAuthStore();
      console.log("getting csrf")
      authStore.getCSRFToken();
    } catch {
      alert('Something went wrong. Please refresh the page or contact the administrator.');
    }

  
  });

</script>

