<template>
    <div class="bar-chart-container card-with-shadow">
        <div class="text-l font-bold">
            Complaints Chart
        </div>
            <Chart type="radar" :data="chartData" :options="chartOptions" />
    </div>

</template>


<script setup>
    import { ref, onMounted } from "vue";
    import Chart from "primevue/chart";
    const props = defineProps({
        chartData: {
            type: Object,
            required: true,
        },
    })
    const chartOptions = ref();
    onMounted(() => {
        chartOptions.value = setChartOptions();
    })

    const setChartOptions = () => {
        const documentStyle = getComputedStyle(document.documentElement);
        const textColor = documentStyle.getPropertyValue('--p-text-color');
        const textColorSecondary = documentStyle.getPropertyValue('--p-text-muted-color');

        return {

            plugins: {
                legend: {
                    display: false,
                    labels: {
                        color: textColor
                    }
                }
            },
            scales: {
                r: {
                    grid: {
                        display: true,
                    },
                    angleLines: {
                        display: false
                    },
                    ticks: {
                        display: false,
                        stepSize: 20,
                    },
                    suggestedMin: 0,
                    suggestedMax: 100
                }
            }
        };
    }
</script>

<style scoped lang="scss">

</style>
