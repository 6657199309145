<template>
    <div class="feedback-content">
        <div class="feedback-details">
            <div class="origin-rating-priority-container">
                <div class="logo-container">
                    <img :src="getOriginLogo(feedback.source)" alt="Origin Logo" />
                </div>
                <Rating v-model="adjustedRating" readonly />
                <Tag :value="'Priority: ' + feedback.feedbackAnalysis?.priority" :severity="getSeverityFromPriority(feedback.feedbackAnalysis.priority)" />
            </div>
            <div class="date-saved-container">
                <p>24/07/2022</p>
                <FontAwesomeIcon :icon="['fas', 'bookmark']" size="xl" :class="isLiked ? 'heart-selected' : 'heart-unselected'" @click="emit('saveClicked')" />
            </div>
        </div>
        <div class=" feedback-text" v-html="displayedText">
        </div>

        <Button :label="showOriginalText ? 'Show Translated Text' : 'Show Original Text'" @click="showOriginalText = !showOriginalText" text raised />

    </div>

</template>


<script setup>
    import { ref, onMounted, computed } from 'vue';
    import Rating from 'primevue/rating';
    import Button from 'primevue/button';
    import Tag from 'primevue/tag';
    import { getOriginLogo, getSeverityFromPriority } from '../../utils/utils';
    const props = defineProps({
        feedback: {
            type: Object,
            required: true,
        },
        isLiked: {
            type: Boolean,
            required: true,
        },
    })
    const showOriginalText = ref(false);
    const emit = defineEmits(['saveClicked'])
    const adjustedRating = ref(0)
    const highlightedEnglishText = ref('');
    const displayedText = computed(() => {
        return showOriginalText.value ? props.feedback.text : highlightedEnglishText.value
    });

    onMounted(() => {
        adjustedRating.value = props.feedback.rating / 2;
        highlightedEnglishText.value = props.feedback.englishText;

        for (let i = 0; i < props.feedback.feedbackAnalysis.keyPhrases.length; i++) {
            const keyPhrase = props.feedback.feedbackAnalysis.keyPhrases[i];
            highlightText(keyPhrase.text, keyPhrase.sentiment);
        }
    });

    function highlightText(text, sentiment) {
        // we want to surround matching text with spans with a class of highlight
        if (sentiment < 0) {
            highlightedEnglishText.value = highlightedEnglishText.value.replace(text, `<span style="background-color:#e6171790;margin-right:2px;">${text}</span>`);
        }
        else if (sentiment < 50) {
            highlightedEnglishText.value = highlightedEnglishText.value.replace(text, `<span style="background-color:#f2b00790;margin-right:2px;">${text}</span>`);
        }
        else {
            highlightedEnglishText.value = highlightedEnglishText.value.replace(text, `<span style="background-color:#11f04c90;margin-right:2px;">${text}</span>`);
        }
    }



</script>

<style scoped lang="scss">
    .feedback-content {
        display: flex;
        flex-direction: column;
        gap: 12px;

        .feedback-details {
            display: flex;
            justify-content: space-between;

            .origin-rating-priority-container {
                display: flex;
                align-items: center;
                gap: 20px;

                .logo-container {
                    width: 40px;
                    height: 40px;
                    display: flex;
                    flex-shrink: 0;

                    img {
                        height: 100%;
                        width: 100%;
                        border-radius: 50%;
                        border: 1px solid var(--border-color);
                        object-fit: contain;
                        padding: 1px;
                    }
                }
            }

            .date-saved-container {
                display: flex;
                align-items: center;
                gap: 20px;

                .heart-selected {
                    color: red;
                    cursor: pointer;
                }

                .heart-unselected {
                    cursor: pointer;
                }
            }

            .p-rating {
                --p-rating-icon-color: gold;
                --p-rating-icon-active-color: gold;

            }
        }

        .feedback-text {
            height: 30vh;
            overflow: auto;


        }

    }
</style>