<template>
    <div class="projects-container">
        <Breadcrumb>
            <template #title>
                <h1>Projects</h1>
            </template>
        </Breadcrumb>
        <div class="card-with-shadow min-h-screen">
            <h2 class="mb-4">
                All company projects are displayed here:
            </h2>
            <div class="projects grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-4">
                <template v-for="project in projects" :key="project.id">
                    <div class="card-with-shadow cursor-pointer flex flex-col justify-between items-center" @click="onProjectClick(project)">
                        <div class="img w-20 h-20 mb-2">
                            <img :src="project.image" alt="Logo" />
                        </div>
                        <div class="font-bold h-10 mb-2">
                            {{ project.name }}
                        </div>
                        <div class="text-sm h-20 line-clamp-3 break-all">
                            {{ project.description }}
                        </div>
                    </div>

                </template>
                <div class="card-with-shadow cursor-pointer flex flex-col justify-between items-center" @click="onAddProject()">
                    <div class="img w-20 h-20 mb-2">
                        <img src="@/assets/plus-image.png" alt="Logo" />
                    </div>
                    <div class="font-bold h-10 mb-2">
                        Add new project.
                    </div>
                    <div class="text-sm h-20 line-clamp-3 break-all">
                        Break down your project in as many sub-projects as you want for optimal management.
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { onMounted, ref } from 'vue';
    import Breadcrumb from '../components/Common/Breadcrumb.vue';
    import { projectService } from '@/services/projectService';
    import { useRouter } from 'vue-router';
    const router = useRouter();
    const projects = ref(null);
    onMounted(async () => {
        projects.value = await projectService.getProjects();

    })
    function onProjectClick(project) {
        router.push(`/${project.slug}/dashboard`)
    }
    function onAddProject() {
        alert("Coming soon.")
    }
</script>