<template>
    <div class="csv-modal-container">
        <Dialog header="Add CSV Source" v-model:visible="visible" class="csv-modal" :style="{ width: '70vw'}">
            <FileUpload  name="demo[]" @upload="onTemplatedUpload($event)" :multiple="true" accept=".csv" :maxFileSize="1000000" @select="onSelectedFiles" >
                <template #header="{ chooseCallback, uploadCallback, clearCallback, files }">
                    <div class="flex flex-wrap justify-between items-center flex-1 gap-4">
                        <div class="flex gap-2">
                            <Button @click="chooseCallback()" icon="pi pi-file" rounded outlined severity="secondary"></Button>
                            <Button @click="uploadEvent(onSubmit)" icon="pi pi-cloud-upload" rounded outlined severity="success" :disabled="!files || files.length === 0"></Button>
                            <Button @click="clearCallback()" icon="pi pi-times" rounded outlined severity="danger" :disabled="!files || files.length === 0"></Button>
                        </div>
                        <ProgressBar :value="totalSizePercent" :showValue="false" class="md:w-20rem h-1 w-full md:ml-auto">
                            <span class="whitespace-nowrap">{{ totalSize }}B / 1Mb</span>
                        </ProgressBar>
                    </div>
                </template>
                <template #content="{ files, uploadedFiles, removeUploadedFileCallback, removeFileCallback }">
                    <div class="flex flex-col gap-8 pt-4">
                        <div v-if="files.length > 0">
                            <h5>Pending</h5>
                            <div class="flex flex-wrap gap-4">
                                <div v-for="(file, index) of files" :key="file.name + file.type + file.size" class="p-8 rounded-border flex flex-col border border-surface items-center gap-4">
                                  
                                    <span class="font-semibold text-ellipsis max-w-60 whitespace-nowrap overflow-hidden">{{ file.name }}</span>
                                    <div>{{ formatSize(file.size) }}</div>
                                    <Badge value="Pending" severity="warn" />
                                    <Button icon="pi pi-times" @click="onRemoveTemplatingFile(file, removeFileCallback, index)" outlined rounded severity="danger" />
                                </div>
                            </div>
                        </div>

                        <div v-if="uploadedFiles.length > 0">
                            <h5>Completed</h5>
                            <div class="flex flex-wrap gap-4">
                                <div v-for="(file, index) of uploadedFiles" :key="file.name + file.type + file.size" class="p-8 rounded-border flex flex-col border border-surface items-center gap-4">
                                   
                                    <span class="font-semibold text-ellipsis max-w-60 whitespace-nowrap overflow-hidden">{{ file.name }}</span>
                                    <div>{{ formatSize(file.size) }}</div>
                                    <Badge value="Completed" class="mt-4" severity="success" />
                                    <Button icon="pi pi-times" @click="removeUploadedFileCallback(index)" outlined rounded severity="danger" />
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <template #empty>
                    <div class="flex items-center justify-center flex-col">
                        <i class="pi pi-cloud-upload !border-2 !rounded-full !p-8 !text-4xl !text-muted-color cursor-pointer"  />
                        <p class="mt-6 mb-0">Drag and drop files to here to upload.</p>
                    </div>
                </template>
            </FileUpload>
            <div class="py-2 text-xl">
                <span class="font-bold">
                    Required columns:
                </span>
                feedback_text
            </div>
            <div class="py-2 mb-4 text-xl">
                <span class="font-bold">
                    Optional columns:
                </span>
                name, surname, username, email, rating, submitted_at, reply_text, reply_date, app_version, original_id
            </div>
      
        </Dialog>

    </div>


</template>

<script setup>
    import Dialog from "primevue/dialog";
    import { ref } from "vue";
    import { useToast } from "primevue/usetoast";
    import Button from "primevue/button";
    import FileUpload from "primevue/fileupload";
    import ProgressBar from "primevue/progressbar";
    import Badge from "primevue/badge";
    import feedbackService from "../../../services/feedbackService";
    const toast = useToast();
    const totalSize = ref(0);
    const totalSizePercent = ref(0);
    const files = ref([]);
    const visible = defineModel()
    const uploadedFiles = ref([])
    function onSubmit() {
        console.log("onSubmit", files.value)
        files.value.forEach((file)=>{
            feedbackService.uploadFeedback(file).then((feedback_count) => {
                files.value.splice(files.value.indexOf(file), 1);
                uploadedFiles.value.push(file)
                toast.add({ severity: "success", summary: `Successfully added ${feedback_count} feedback found in file: ${file.name}`, life: 3000 });
            }).catch((error) => {
                console.log("error", error)
            })
        })
    }
    const onRemoveTemplatingFile = (file, removeFileCallback, index) => {
        removeFileCallback(index);
        totalSize.value -= parseInt(formatSize(file.size));
        totalSizePercent.value = 0
    };

    const onClearTemplatingUpload = (clear) => {
        clear();
        totalSize.value = 0;
        totalSizePercent.value = 0;
    };

    const onSelectedFiles = (event) => {
        files.value = event.files;
        files.value.forEach((file) => {
            totalSize.value += parseInt(formatSize(file.size));
        });
    };

    const uploadEvent = (callback) => {
        console.log("uploadEvent", callback)
        console.log("totalSize", totalSize.value)
        console.log("files",files.value)
        totalSizePercent.value = 100
        callback();
    };

    const onTemplatedUpload = () => {
        toast.add({ severity: "info", summary: "Success", detail: "File Uploaded", life: 3000 });
    };

    const formatSize = (bytes) => {
    const k = 1024;
    const dm = 2;  // Decimal places to show
    const sizeUnits = ["Bytes", "KB", "MB", "GB", "TB"];

    if (bytes === 0) {
        return `0 ${sizeUnits[0]}`;
    }

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    const formattedSize = parseFloat((bytes / Math.pow(k, i)).toFixed(dm));

    return `${formattedSize} ${sizeUnits[i]}`;
};
</script>