// api/index.js
import axios from 'axios';
import Cookies from 'js-cookie'

const api = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  timeout: 10000, // 10 seconds
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
  withCredentials: true,
});

const fileClient = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  timeout: 10000, // 10 seconds
  headers: {
    'Content-Type': 'multipart/form-data',
    'Accept': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
  withCredentials: true,
});


api.interceptors.request.use(
  (config) => {
    // Only add CSRF token to state-changing requests
    if (['post', 'put', 'patch', 'delete'].includes(config.method.toLowerCase())) {
      const csrfToken = Cookies.get('csrftoken');
      config.headers['X-CSRFToken'] = csrfToken;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);


// Global error handler for responses
api.interceptors.response.use(
  (response) => response,
  (error) => {
    // Timeout error handling
    if (error.code === 'ECONNABORTED' && error.message.includes('timeout')) {
      console.error('Request timed out:', error.config);
    } else if (error.response) {
      // Handle other HTTP errors
      console.error('Response error:', error.response.status, error.response.data);
    } else {
      // General errors (network, etc.)
      console.error('Error:', error.message);
    }
    return Promise.reject(error);
  }
);

fileClient.interceptors.request.use(
  (config) => {
    // Only add CSRF token to state-changing requests
    if (['post', 'put', 'patch', 'delete'].includes(config.method.toLowerCase())) {
      const csrfToken = Cookies.get('csrftoken');
      config.headers['X-CSRFToken'] = csrfToken;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);


// Global error handler for responses
fileClient.interceptors.response.use(
  (response) => response,
  (error) => {
    // Timeout error handling
    if (error.code === 'ECONNABORTED' && error.message.includes('timeout')) {
      console.error('Request timed out:', error.config);
    } else if (error.response) {
      // Handle other HTTP errors
      console.error('Response error:', error.response.status, error.response.data);
    } else {
      // General errors (network, etc.)
      console.error('Error:', error.message);
    }
    return Promise.reject(error);
  }
);

// API endpoints
export const authAPI = {
  getCSRFToken: () => api.get('/accounts/get-csrf-token/'),
  login: (credentials) => api.post('/accounts/login/', credentials),
  logout: () => api.post('/accounts/logout/'),
  checkAuth: () => api.get('/accounts/check-auth/'),
  updateUserSettings: (params) => api.post('/accounts/update-user-settings/',params),
};

export const projectAPI = {
  getProjects: () => api.get('accounts/projects/'),
}

export const feedbackAPI = {
  getFeedbackList: (params, projectSlug) => api.get(`/feedback/all/${projectSlug}/`, { params }),
  getFeedbackItem: (id) => api.get(`/feedback/${id}/`),
  likeFeedback: (id, data) => api.post(`/feedback/${id}/like/`, data),
  getSavedFeedback: (params,projectSlug) => api.get(`/feedback/liked/${projectSlug}/`, { params }),
  uploadFeedback: (file) => {
    const formData = new FormData();
    formData.append('file', file);
    return fileClient.post('/feedback/upload-csv/',formData);
  },};

export const mlcoreAPI = {
  getClusters: (params,projectSlug) => api.get(`/mlcore/clusters/${projectSlug}`, { params }),
};
export const dataAggregatorAPI = {
  getDashboardData: (projectSlug) => api.get(`/data-aggregator/dashboard-data/${projectSlug}`),
};
export const connectorsAPI = {
  syncAllSources: (params) => api.post('/connectors/sync-all-sources/',params),
  syncSource: (sourceId) => api.post(`/connectors/sync-source/${sourceId}/`),
  getAllConnectors:(projectSlug) => api.get(`/connectors/all-connectors/${projectSlug}/`),
  addGooglePlayReviewStoreConnector: (params) => api.post('/connectors/add-google-play-store-reviews-connector/',params),
}
export const feedbackAnalysisAPI = {
  analyzeFeedback:(params)=> api.post('/feedback-analysis/analyze-feedback/',params),
}

// Error handling helper
export const handleApiError = (error) => {
  if (error.response) {
    console.error('API Error:', error.response.data);
    console.error('Status:', error.response.status);
    return {
      message: error.response.data.detail || 'An error occurred',
      status: error.response.status,
    };
  } else if (error.request) {
    console.error('No response received:', error.request);
    return {
      message: 'No response from server',
      status: 0,
    };
  } else {
    console.error('Error setting up request:', error.message);
    return {
      message: 'Error setting up request',
      status: 0,
    };
  }
};

export default api;