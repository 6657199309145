<template>
    <div class="settings-container">
        <Breadcrumb>
            <template #title>
                <h1>Settings</h1>
            </template>
        </Breadcrumb>
        <div class="settings-content card-with-shadow">
            <div class="setting-options py-4">
                <div class="flex align-center gap-4">
                    <label for="show-only-english">Show all feedback in English:</label>
                    <ToggleSwitch v-model="isShowOnlyEnglishChecked" id="show-only-english"/>
                </div>
            </div>
            <div class="submit-buttons flex justify-end border-t py-2">
                <Button :disabled="noSettingsChanged" label="Save Changes" @click="onSaveChanges"class="w-full md:w-56" />
            </div>
        </div>
    </div>
</template>

<script setup>
    import { computed, onMounted, ref, watch } from "vue"
    import Breadcrumb from '../components/Common/Breadcrumb.vue';
    import ToggleSwitch from 'primevue/toggleswitch';
    import Button from 'primevue/button'
    import { authService } from "../services/authService";
    import Toast from 'primevue/toast';
    import { useToast } from 'primevue/usetoast';
    import { useAuthStore } from "../stores/auth";
    const authStore = useAuthStore()
    const toast = useToast();
    const isShowOnlyEnglishChecked=ref(true)
    const anySettingsChanged=ref(false)
    const noSettingsChanged = computed(()=>{
        return authStore.user.user_settings.show_only_english === isShowOnlyEnglishChecked.value
    })
    onMounted(()=>{
        isShowOnlyEnglishChecked.value = authStore.user.user_settings.show_only_english

    })
    function onSaveChanges() {
        try{
            authService.updateUserSettings({show_only_english:isShowOnlyEnglishChecked.value}).then(()=>{
                anySettingsChanged.value=false
                authStore.user.user_settings.show_only_english = isShowOnlyEnglishChecked.value
                toast.add({ severity: 'success', summary: 'Success Message', detail: 'Settings saved successfully', life: 2000 });
            })
        }catch(e){
            toast.add({ severity: 'danger', summary: 'Failed Message', detail: 'Settings not saved. Try again or contact your administrator.', life: 2000 });

        }
    }
</script>